import { ReferralBonusTypes } from "@src/appV2/Accounts/WorkerReferrals/types";
import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import request from "superagent";

import { getAuthHeader } from "../superagent";

interface Referral {
  referrerName: string;
  referrerCode: string;
  bonusValue?: number;
  bonusPaid?: boolean;
  bonusType: ReferralBonusTypes;
  bonusExpectationMessage?: string;
}

export interface ReferralHowItWorksProps {
  referralRate: string;
  maxReferralBonusAmount: number;
  stepItems: string[];
}

const listReferrals = async (): Promise<Referral[] | undefined> => {
  try {
    const { body } = await request
      .get(`${environmentConfig.REACT_APP_BASE_API_URL}/agentprofile/referrals`)
      .retry(1, (err) => {
        if (!err) {
          return false;
        }
        return true;
      })
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

const submitReferralCode = async (
  referralCode: string
): Promise<{ referralCodeValid: boolean; message: string } | undefined> => {
  try {
    const { body } = await request
      .post(`${environmentConfig.REACT_APP_BASE_API_URL}/agentprofile/referral`)
      .send({ referralCode })
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    const { response } = error;
    if (response?.body) {
      return response.body;
    }
    logApiFailureEvent(error);
  }
};

export { listReferrals, submitReferralCode, Referral };
