import "./style.scss";
import { Clipboard } from "@capacitor/clipboard";
import { Share } from "@capacitor/share";
import { Text } from "@clipboard-health/ui-react";
import { IonButton, IonIcon, IonSpinner } from "@ionic/react";
import { Stack } from "@mui/material";
import {
  CbhFeatureFlag,
  EnableReferralSignupScreenVariants,
  useCbhFlags,
} from "@src/appV2/FeatureFlags";
import { AppBarHeader, BackButtonLink, PageWithHeader, useToast } from "@src/appV2/lib";
import { deprecatedDoNotUseLogError, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { LinkCard } from "@src/appV2/lib/Links/LinkCard";
import { useActivationReferralShareMessage } from "@src/appV2/Referral/Activation/useActivationReferralShareMessage";
import { useMaxReferralBonusAmount } from "@src/appV2/Referral/Bonus/flags";
import { PaymentServiceAccountStatus } from "@src/appV2/Worker/api/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useUpdateWorkerCache } from "@src/appV2/Worker/useUpdateWorkerCache";
import { SUPPORT_LINKS } from "@src/constants/DEFAULT_SUPPORT_LINKS";
import { copySharp } from "ionicons/icons";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { HowItWorksCard } from "./components/howItWorksCard";
import {
  ReferralBonusAvailabilityBannerMessage,
  stepItemsWithActivationReferrals,
  stepItemsWithoutActivationReferrals,
} from "./constants";
import { REFERRALS } from "../../constants";
import { USER_EVENTS } from "../../constants/userEvents";
import { updateShowReferralAlert } from "../account/api";
import {
  getLicensesForSignUpBasedReferralsMSA,
  getSortedBonusValues,
  shareSignUpBasedReferralLink,
} from "../onboarding/components/SignUpBasedReferrals/utils";
import { ShouldBeOnboardedStatuses } from "../onboardingStripe/model";
import { TabRouterPath } from "../routing/constant/tabRoute";
import { useSession } from "../store/helperHooks";

export function ReferralPage() {
  const { showSuccessToast, showErrorToast } = useToast();
  const history = useHistory();
  const { referralRate } = useSession();

  const worker = useDefinedWorker();
  const updateWorkerCache = useUpdateWorkerCache();

  const maxReferralBonusAmount = useMaxReferralBonusAmount("postOnboardingBonusInDollars");
  const shouldOnboardToStripe = ShouldBeOnboardedStatuses.includes(
    worker.paymentAccountInfo?.status as PaymentServiceAccountStatus
  );
  const { referralCode = "" } = worker ?? {};
  const activationReferralShareMessage = useActivationReferralShareMessage(referralCode);
  const {
    [CbhFeatureFlag.ENABLE_REFERRAL_SIGNUP_SCREEN]: enableSignupBasedReferral,
    [CbhFeatureFlag.SIGNUP_BASED_REFERRAL]: signUpBasedReferral,
    [CbhFeatureFlag.ENABLED_REFERRAL_PROGRAMS]: enabledReferralPrograms,
    [CbhFeatureFlag.SUPPORT_LINKS]: supportLinks,
  } = useCbhFlags();

  const activationBasedReferralIsEnabled =
    enabledReferralPrograms?.activationReferralIsEnabled ?? true;
  const referralBonusSupportLink = supportLinks?.REFERRAL_BONUS ?? SUPPORT_LINKS.REFERRAL_BONUS;

  useEffect(() => {
    if (!worker) {
      return;
    }
    if (!worker.showReferralAlert) {
      return;
    }
    updateShowReferralAlert(worker.userId, { showReferralAlert: false }).then(() => {
      updateWorkerCache({ showReferralAlert: false });
    });
  }, [updateWorkerCache, worker]);

  useLogEffect(USER_EVENTS.VIEWED_REFER_A_CO_WORKER, {
    hcpID: worker.userId,
  });

  const onShareForSignUpBasedReferralTargetedUsers = () => {
    const msa = worker.address?.metropolitanStatisticalArea ?? "";
    const edges = getSortedBonusValues(signUpBasedReferral, msa);
    const licenses = getLicensesForSignUpBasedReferralsMSA(signUpBasedReferral, msa);
    return shareSignUpBasedReferralLink({
      worker: worker,
      bonuses: {
        simple: edges.map((value) => `$${value}`).join(" to "),
        maxReferralAmountRange: edges.map((value) => `$${(value as number) * 5}`).join(" to "),
        maxReferralAmountPerUser: edges[1] as number,
        maxReferralAmount: (edges[1] as number) * 5,
      },
      licenses,
      activationBasedReferralParams: {
        isEnabled: activationBasedReferralIsEnabled,
        activationReferralShareMessage,
      },
    })
      .then(() => {
        logEvent(USER_EVENTS.REFER_A_FRIEND_PAGE_REFERRAL_CODE_SHARED, {
          referralCode: referralCode,
          workerId: worker.userId,
        });
      })
      .catch((error) => {
        deprecatedDoNotUseLogError({
          message: `Unable to share referral code on Refer a friend screen - ${JSON.stringify(
            error?.stack || error
          )}`,
          metadata: {
            workerId: worker.userId,
            msa,
          },
        });
      });
  };

  const onShare = async () => {
    if (!worker) {
      return;
    }
    if (
      enableSignupBasedReferral &&
      enableSignupBasedReferral !== EnableReferralSignupScreenVariants.OFF
    ) {
      return onShareForSignUpBasedReferralTargetedUsers();
    }

    try {
      await Share.share({
        dialogTitle: "Invite to join Clipboard Health",
        title: `Download the Clipboard Health app for a ${referralRate} bonus!`,
        text: activationBasedReferralIsEnabled
          ? activationReferralShareMessage
          : `Hey! I work through Clipboard Health, and I love it! Sign up within 24 hours using my referral code ${referralCode} and we'll BOTH get a $50 bonus. Note that referral bonuses are available only in selected areas. 😊`,
      });
      logEvent(USER_EVENTS.TAPPED_LINK_TO_REFER_A_CO_WORKER, {
        inviteUrl: "",
      });
    } catch (error) {
      showErrorToast("Could not share invitation");
      logEvent(USER_EVENTS.REFERRAL_LINK_ERROR, { inviteUrl: "" });
    }
  };

  const onCopyToClipboard = async () => {
    try {
      await Clipboard.write({
        string: referralCode,
      });
      showSuccessToast("Referral code copied to clipboard!");
    } catch (err) {
      showErrorToast("Sorry, we couldn't copy the code to clipboard");
    }
  };

  if (!worker) {
    return <IonSpinner color="light" />;
  }
  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title={REFERRALS.REFER_A_FRIEND}
          leftCta={<BackButtonLink defaultBackTo={TabRouterPath.ACCOUNT} />}
        />
      }
      containerMaxWidth="xs"
    >
      <Stack spacing={2} className="referral-container">
        {!activationBasedReferralIsEnabled && (
          <LinkCard
            title={
              <Text variant="body2" bold>
                {ReferralBonusAvailabilityBannerMessage.title}
              </Text>
            }
            subtitle={
              <Text variant="caption">{ReferralBonusAvailabilityBannerMessage.subtitle}</Text>
            }
            to={referralBonusSupportLink}
            variant="warning"
          />
        )}
        <HowItWorksCard
          referralRate={referralRate || ""}
          maxReferralBonusAmount={maxReferralBonusAmount}
          stepItems={
            activationBasedReferralIsEnabled
              ? stepItemsWithActivationReferrals
              : stepItemsWithoutActivationReferrals
          }
        ></HowItWorksCard>

        {!shouldOnboardToStripe ? (
          <>
            <span
              style={{
                fontSize: 18,
                fontWeight: "bold",
                color: "black",
                marginBottom: -12,
              }}
            >
              Your referral code:
            </span>
            <div className="card">
              <span
                data-testid="agent-referral-code"
                style={{
                  fontSize: 24,
                  fontWeight: "bold",
                  color: "black",
                  marginRight: 8,
                  fontFamily: "Courier",
                }}
              >
                {referralCode}
              </span>
              <IonIcon
                data-testid="copy-to-clipboard-icon"
                aria-label="Copy referral code to clipboard"
                onClick={onCopyToClipboard}
                icon={copySharp}
                color="primary"
                size="small"
              />
            </div>
          </>
        ) : (
          <IonButton
            expand="block"
            onClick={() => {
              history.push(TabRouterPath.PAYMENT_SERVICE_ONBOARDING, {
                showBackButton: true,
              });
            }}
            className="spaced-button"
          >
            Sign up with Stripe to view and
            <br />
            share your referral code!
          </IonButton>
        )}

        <>
          <IonButton
            onClick={onShare}
            disabled={shouldOnboardToStripe}
            className="disabled-button-variant"
          >
            Share your code with colleagues
          </IonButton>
        </>
      </Stack>
    </PageWithHeader>
  );
}
