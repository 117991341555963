import "./style.scss";
import { Geolocation } from "@capacitor/geolocation";
import { useModalState } from "@clipboard-health/ui-react";
import { ExternalLink } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { IonAlert, IonIcon, IonInput, IonSpinner } from "@ionic/react";
import { Stack } from "@mui/material";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { useSession } from "@src/app/store/helperHooks";
import {
  LocationPermissionDialog,
  LocationVerificationFailed,
  LocationVerificationInProgressDialog,
} from "@src/appV2/Accounts/WorkerReferrals/components/LocationVerificationStates";
import { ReferralBonusTypes } from "@src/appV2/Accounts/WorkerReferrals/types";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { AppBarHeader, BackButtonLink, PageWithHeader, isCapacitorPlatform } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useCbhLocationVerification } from "@src/appV2/Location/hooks";
import { useReferralsEligibility } from "@src/appV2/Referral/api/useReferralsEligibility";
import { SupportArticles, SupportContext } from "@src/appV2/support/constants";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { SUPPORT_LINKS } from "@src/constants/DEFAULT_SUPPORT_LINKS";
import { checkmarkCircle, closeCircle, personAddSharp } from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";

import { REFERRALS } from "../../../../constants";
import { Referral, listReferrals, submitReferralCode } from "../../api";
import { IneligibleForReferralsCard } from "../../components/ineligibleForReferralsCard";
import { ReferralBonusEligibilityBannerMessage } from "../../constants";

export function CoWorkerReferralPage() {
  const [isValidReferralCode, setIsValidReferralCode] = useState(true);
  const [isCodeSubmitted, setIsCodeSubmitted] = useState(false);
  const { referralRate } = useSession();
  const worker = useDefinedWorker();
  const [referralCode, setReferralCode] = useState("");
  const [loadingValidate, setLoadingValidate] = useState(false);
  const [overrideModalOpen, setOverrideModalOpen] = useState(false);
  const [referrals, setReferrals] = useState<Referral[] | undefined>([]);
  const [validationMessage, setValidationMessage] = useState("");
  const [referralCodeApplied, setReferralCodeApplied] = useState("");

  const locationVerificationModalState = useModalState();
  const errorModalState = useModalState();

  const {
    data: workerReferralEligibility,
    isLoading: isLoadingReferralsEligibility,
    isSuccess: isWorkerReferralEligibilitySuccess,
  } = useReferralsEligibility({ workerId: worker.userId });

  const supportLinks = useCbhFlag(CbhFeatureFlag.SUPPORT_LINKS, {
    defaultValue: {
      ACCOUNT_STATUS: SUPPORT_LINKS.ACCOUNT_STATUS,
      REFERRAL_BONUS: SUPPORT_LINKS.REFERRAL_BONUS,
    },
  });
  const {
    enableCoreRequirementsVerification: isCoreRequirementsVerificationEnabled,
    enableLocationVerification: isLocationVerificationEnabled,
  } = useCbhFlag(CbhFeatureFlag.ENABLED_SIGNUP_REFERRAL_GUARD_RAILS, {
    defaultValue: {
      enableCoreRequirementsVerification: true,
      enableLocationVerification: true,
    },
  });

  // By default, assume the worker is eligible. The referral code will be verified once it is submitted.
  const isEligibleForAtLeastOneReferral =
    isWorkerReferralEligibilitySuccess && isDefined(workerReferralEligibility)
      ? workerReferralEligibility?.data.some((element) => element.attributes.eligible)
      : true;

  const loadReferrals = useCallback(async () => {
    const referrals = await listReferrals();
    setReferrals(referrals);
    setReferralCodeApplied(referrals && referrals[0] ? referrals[0].referrerCode : "");
  }, []);

  useEffect(() => {
    loadReferrals();
  }, [loadReferrals]);

  const handleSubmitReferralCode = async (code) => {
    try {
      setLoadingValidate(true);
      const response = await submitReferralCode(code);
      setIsCodeSubmitted(true);

      if (!response) {
        setValidationMessage("Something went wrong");
        setIsValidReferralCode(false);
        setLoadingValidate(false);
        return;
      }

      setValidationMessage(response.message);
      setIsValidReferralCode(response.referralCodeValid);
      setLoadingValidate(false);

      if (response.referralCodeValid) {
        loadReferrals();
      }
    } catch (err) {
      setValidationMessage("Something went wrong");
      setIsValidReferralCode(false);
      setLoadingValidate(false);
    }
  };

  const {
    isLoading: isVerifyingLocationLoading,
    mutateAsync: verifyLocation,
    reset: resetLocationVerification,
  } = useCbhLocationVerification({
    originCoordinates: {
      latitude: worker.geoLocation?.coordinates?.[1] ?? 0,
      longitude: worker.geoLocation?.coordinates?.[0] ?? 0,
    },
  });

  const validateReferralCode = async (code) => {
    if (
      referralCodeApplied &&
      referralCodeApplied !== code &&
      !worker.referralCodeUsed?.referredBonusId
    ) {
      setOverrideModalOpen(true);
      setIsCodeSubmitted(false);
      return;
    }
    if (!isLocationVerificationEnabled) {
      await handleSubmitReferralCode(code);
      return;
    }
    await verifyHcpLocationAndOpenLocationVerificationDialog(code);
  };

  const verifyHcpLocationAndOpenLocationVerificationDialog = async (code) => {
    const geoLocationPermission = await Geolocation.checkPermissions();
    if (geoLocationPermission.location !== "granted") {
      locationVerificationModalState.openModal();
      return;
    }
    const { isLocationVerified } = await verifyLocation();
    if (!isLocationVerified) {
      errorModalState.openModal();
      return;
    }
    await handleSubmitReferralCode(code);
  };

  const onChangeReferralCode = async (event) => {
    setIsCodeSubmitted(false);
    const { value } = event.detail;

    if (loadingValidate || value.length > 8) {
      return;
    }
    setReferralCode(value);
    if (value.length === 8) {
      await validateReferralCode(value);
      return;
    }
  };

  function getInputBorderColor() {
    if (isCodeSubmitted) {
      if (isValidReferralCode) {
        return "#18DC68";
      } else {
        return "#DC3028";
      }
    }

    return "black";
  }

  const referralBonusSupportLink = isDefined(supportLinks?.REFERRAL_BONUS)
    ? supportLinks.REFERRAL_BONUS
    : SUPPORT_LINKS.REFERRAL_BONUS;

  if (!worker) {
    return <IonSpinner color="light" />;
  }
  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title={REFERRALS.REDEEM_REFERRAL_CODE}
          leftCta={<BackButtonLink defaultBackTo={TabRouterPath.ACCOUNT} />}
        />
      }
      containerMaxWidth="xs"
    >
      <Stack spacing={2} className="coworker-referal-container">
        {isLoadingReferralsEligibility ? (
          <div className="ion-text-center ion-margin">
            <IonSpinner name="lines" className="checkr-spinner" />
          </div>
        ) : (
          <>
            {!isEligibleForAtLeastOneReferral ? (
              <IneligibleForReferralsCard
                title={ReferralBonusEligibilityBannerMessage.title}
                subtitle={ReferralBonusEligibilityBannerMessage.subtitle}
                supportLink={referralBonusSupportLink}
                onClick={() => {
                  logEvent(APP_V2_USER_EVENTS.OPENED_SUPPORT_ARTICLE, {
                    articleLink: referralBonusSupportLink,
                    articleName: SupportArticles.REFERRAL_BONUS,
                    supportContext: SupportContext.NO_REFERRAL_PROGRAM,
                  });
                }}
              />
            ) : (
              <div className="card">
                <div className="title" style={{ color: "black" }}>
                  Enter a referral code
                </div>
                <div className="divider"></div>
                <IonInput
                  clearInput
                  aria-label="Enter a referral code"
                  value={referralCode}
                  debounce={100}
                  disabled={loadingValidate}
                  onIonChange={onChangeReferralCode}
                  maxlength={8}
                  style={{
                    width: "95%",
                    margin: "15px 10px",
                    border: `2px solid ${getInputBorderColor()}`,
                    borderRadius: "5px",
                  }}
                >
                  {loadingValidate && (
                    <IonSpinner
                      slot="start"
                      name="lines"
                      style={{ width: 12, height: 12, marginRight: 10 }}
                    />
                  )}
                </IonInput>

                {isCodeSubmitted && (
                  <div
                    className={
                      isValidReferralCode ? "validationContainer isValid" : "validationContainer"
                    }
                  >
                    <IonIcon icon={isValidReferralCode ? checkmarkCircle : closeCircle}></IonIcon>
                    <span>{validationMessage}</span>
                  </div>
                )}
              </div>
            )}
            <div className="referralListContainer">
              {referrals?.map((referral) => {
                const coreRequirementsEnabledForUnpaidSignUpBonus =
                  isCoreRequirementsVerificationEnabled &&
                  !referral.bonusPaid &&
                  referral.bonusType === ReferralBonusTypes.SIGN_UP;
                return (
                  <div
                    className={referral.bonusPaid ? "referralCard wasPaid" : "referralCard"}
                    key={referral.referrerCode}
                  >
                    <IonIcon icon={personAddSharp}></IonIcon>
                    <div className="referrerInfo">
                      <span className="referrerName">Referral | {referral.referrerName}</span>
                      <strong className="referrerCode">{referral.referrerCode}</strong>
                    </div>
                    <div className="bonusInfo">
                      <span className="bonusAmount">${referral.bonusValue || referralRate}</span>
                      <span className="bonusStatus">
                        {coreRequirementsEnabledForUnpaidSignUpBonus && (
                          <>
                            Paid after you are{" "}
                            <ExternalLink
                              to={supportLinks.ACCOUNT_STATUS ?? SUPPORT_LINKS.ACCOUNT_STATUS}
                            >
                              enrolled
                            </ExternalLink>
                          </>
                        )}
                        {!coreRequirementsEnabledForUnpaidSignUpBonus &&
                          !referral.bonusPaid &&
                          referral.bonusExpectationMessage}
                        {referral.bonusPaid && "Paid!"}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </Stack>

      <IonAlert
        isOpen={overrideModalOpen}
        onDidDismiss={() => setOverrideModalOpen(false)}
        header={"Only one referral code can be applied."}
        message={"Update to apply new code, or keep existing code?"}
        backdropDismiss={true}
        buttons={[
          {
            text: "Update",
            handler: async () =>
              await verifyHcpLocationAndOpenLocationVerificationDialog(referralCode),
          },
          {
            text: "Keep",
            role: "cancel",
            handler: () => setReferralCode(""),
          },
        ]}
      />
      <LocationPermissionDialog
        modalState={locationVerificationModalState}
        onContinue={async () => {
          if (isCapacitorPlatform()) {
            await Geolocation.requestPermissions({
              permissions: ["location"],
            });
          }
          await verifyHcpLocationAndOpenLocationVerificationDialog(referralCode);
        }}
      />
      <LocationVerificationInProgressDialog isLoading={isVerifyingLocationLoading} />
      <LocationVerificationFailed
        modalState={errorModalState}
        resetLocationVerification={resetLocationVerification}
      />
    </PageWithHeader>
  );
}
