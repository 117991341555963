import { Text } from "@clipboard-health/ui-react";
import { LinkCard } from "@src/appV2/lib/Links/LinkCard";

export interface IneligibleForReferralsCardProps {
  title: string;
  subtitle: string;
  supportLink: string;
  onClick: () => void;
}

export const IneligibleForReferralsCard = (props: IneligibleForReferralsCardProps) => {
  const { title, subtitle, supportLink, onClick } = props;
  return (
    <LinkCard
      title={
        <Text variant="body2" bold>
          {title}
        </Text>
      }
      subtitle={<Text variant="caption">{subtitle}</Text>}
      to={supportLink}
      variant="warning"
      onClick={onClick}
    />
  );
};
