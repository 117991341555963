import "./style.scss";
import { ExternalLink } from "@clipboard-health/ui-react";
import styled from "@emotion/styled";
import { IonItem } from "@ionic/react";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { SUPPORT_LINKS } from "@src/constants/DEFAULT_SUPPORT_LINKS";

import { ReferralHowItWorksProps } from "../api";
const Card = styled.div``;
const BulletItem = styled.div``;
const Row = styled.div``;

const Item = ({ title, number }) => {
  return (
    <Row className="row">
      <BulletItem className="bullet-item">{number}</BulletItem>
      <p data-testid={`step-item-${number}`} style={{ marginLeft: 12, fontSize: 18 }}>
        {title}
      </p>
    </Row>
  );
};
export const HowItWorksCard = (props: ReferralHowItWorksProps) => {
  const { maxReferralBonusAmount, stepItems } = props;
  const { [CbhFeatureFlag.SUPPORT_LINKS]: supportLinks = {} } = useCbhFlags();

  return (
    <Card className="how-it-works-card">
      <h1>How it works</h1>
      {stepItems.map((step, index) => (
        <Item title={step} number={index + 1} key={step}></Item>
      ))}
      <IonItem lines="none" style={{ alignSelf: "center" }}>
        <ExternalLink to={supportLinks["REFERRAL_BONUS"] ?? SUPPORT_LINKS.REFERRAL_BONUS}>
          Earn ${maxReferralBonusAmount}. Learn more here.
        </ExternalLink>
      </IonItem>
    </Card>
  );
};
