export enum ActivationShiftHowItWorksSteps {
  SEND_CODE = "Send your code to a friend",
  WORK_A_SHIFT = "They work a shift",
  SIGN_UP = "They sign up",
  GET_BONUS = "You each get a bonus!",
}

export const stepItemsWithActivationReferrals = [
  ActivationShiftHowItWorksSteps.SEND_CODE,
  ActivationShiftHowItWorksSteps.WORK_A_SHIFT,
  ActivationShiftHowItWorksSteps.GET_BONUS,
];

export const stepItemsWithoutActivationReferrals = [
  ActivationShiftHowItWorksSteps.SEND_CODE,
  ActivationShiftHowItWorksSteps.SIGN_UP,
  ActivationShiftHowItWorksSteps.GET_BONUS,
];

export const ReferralBonusAvailabilityBannerMessage = {
  title: "Referral bonuses are available only in selected areas",
  subtitle: "Tap to learn more",
};

export const ReferralBonusEligibilityBannerMessage = {
  title:
    "Referral bonuses aren't available in your area at this time, though they may be in the future. Previously applied bonuses will still be paid.",
  subtitle: "Tap to learn more",
};
